<template>
	<div v-if="loaded" class="opacity-page">
		<div class="settings-content">
			<div class="list-crud row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="list">
						<div class="item" v-for="role in roles" @click="viewItem(role)" v-bind:class="{active: roleSelected.id == role.id}">
                            <div class="tag-color" v-bind:class="['color-' + role.color.name]">
								<span></span>
							</div>
							<div class="name">{{ role.name }}</div>
							<div class="actions">
								<button @click.stop="editRole(role)"><icon-edit /></button>
								<button @click.stop="showModal('delete', role, 'role', 'index_roles')"><icon-trash /></button>
							</div>
						</div>
						<div class="item-empty" v-if="!roles.length">
							{{ $t('roles.no-result') }}
						</div>
					</div>
					<div class="crud" id="list-items">
						<div class="box" v-if="viewCrud == 'create'">
							<div class="header">
								<div class="icon-header"><icon-role /></div>
								<div class="name">{{ $t('roles.create-new-role') }}</div>
							</div>
							<div class="form">
								<div class="input-group">
									<div class="label-header">
										<label class="label">{{ $t('roles.name') }}*</label>
										<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.name.$error}">
										<div class="icon-right" v-if="name != ''" @click.stop="name = ''">
											<icon-close class="icon-clear" />
										</div>
										<input type="text" :placeholder="$t('roles.name-ph')" class="input-text no-icon" v-model="name" ref="first_input">
									</div>
								</div>

								<div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('create-tags.color') }}*</label>
										<div class="color-error" v-bind:class="{has_error: $v.selectedColor.$error, complete: selectedColor != '' && !$v.selectedColor.$error }">{{ $t('roles.color-error') }}</div>
                                    </div>
                                    <div class="radio-colors">
                                        <div class="tag-color can-select" v-for="color in colors" v-bind:class="[selectedColor == color.id ? 'selected color-' + color.name : 'color-' + color.name]" @click="selectColor(color.id)">
                                            <img src="/build/icons/settings-tags-color.svg">
                                            <span></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="input-group" v-bind:class="{complete: selectUsersId != ''}">
                                    <div class="label-header">
                                        <label class="label">{{ $t('create-tags.users') }}</label>
                                    </div>

                                    <div class="input-box bg-white">
                                        <div class="icon-left"><icon-people /></div>
                                        <!-- <div class="icon-right" v-if="selectUsersId != ''" @click.stop="selectUsersId = ''"><icon-plus class="icon-clear"/></div> -->
                                        <multiselect 
                                            :preserve-search="true"
                                            v-model="usersSelected"
                                            class="select-tags-tbf hide-tags"
                                            :options="optionsUsers" 
                                            :multiple="true" 
                                            group-values="groupItems"
                                            group-label="groupName" 
                                            :group-select="true"
                                            :close-on-select="false"
                                            :placeholder="$t('create-tags.search-users')" 
                                            track-by="id" 
                                            label="first_name"
                                            :custom-label="customLabel"
                                            :selectLabel="$t('create-tags.select')"
                                            :selectGroupLabel="$t('create-tags.select-all')"
                                            :selectedLabel="$t('create-tags.selected')"
                                            :deselectLabel="$t('create-tags.deselect')"
                                            :deselectGroupLabel="$t('create-tags.deselect-all')"
                                            @input="selectUserId">
                                                <template slot="placeholder" slot-scope="props">
                                                    <span class="text">
                                                        {{ $t('create-tags.search-users') }}
                                                    </span>
                                                </template>
                                                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('create-tags.selected-users') }}</span></template>
                                                <template slot="singleLabel" slot-scope="props">
                                                    <div class="option__image">
                                                        <span>
                                                            {{ props.option.first_name + ' ' + props.option.last_name }}
                                                        </span>
                                                    </div>
                                                </template>
                                                <template slot="option" slot-scope="props">
                                                    <div class="option__image">
                                                        <img :src="props.option.avatar" v-if="props.option.avatar">
                                                        <img v-else src="/build/images/profile-default.svg">
                                                        <span>
                                                            {{ props.option.first_name + ' ' + props.option.last_name }}
                                                        </span>
                                                    </div>
                                                </template>
                                                <template slot="noResult">{{ $t('create-tags.no-results') }}</template>
                                            </multiselect>
                                    </div>
                                    <div class="selectedValues" v-if="usersSelected.length > 0">
                                        <span v-for="usr, index in usersSelected" class="name">
                                            {{ usr.first_name + ' ' + usr.last_name }}
                                            <img src="/build/icons/settings-icon-delete-tag.svg" class="icon-remove-tag" @click="removeTaguser(usr, index)">
                                        </span>
                                        
                                    </div>
                                </div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')">
										<div class="loader"></div>
										<span class="text">{{$t('general.add')}}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'edit'">
							<div class="header">
								<div class="icon-header"><icon-role /></div>
								<div class="name">{{ roleSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf white only-icon" @click="resetToList('item')"><icon-close /></button>
								</div>
							</div>
							<div class="form">
								<div class="input-group">
									<div class="label-header">
										<label class="label">{{ $t('roles.name') }}*</label>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.name.$error}">
										<div class="icon-right" v-if="name != ''" @click.stop="name = ''">
											<icon-close class="icon-clear" />
										</div>
										<input type="text" :placeholder="$t('roles.name-ph')" class="input-text no-icon" v-model="name">
									</div>
								</div>

								<div class="input-group" v-bind:class="{has_error: $v.selectedColor.$error, complete: selectedColor != '' && !$v.selectedColor.$error }">
                                    <div class="label-header">
                                        <label class="label">{{ $t('create-tags.color') }}*</label>
                                    </div>
                                    <div class="radio-colors">
                                        <div class="tag-color can-select" v-for="color in colors" v-bind:class="[selectedColor == color.id ? 'selected color-' + color.name : 'color-' + color.name]" @click="selectColor(color.id)">
                                            <img src="/build/icons/settings-tags-color.svg">
                                            <span></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="input-group" v-bind:class="{complete: selectUsersId != ''}">
                                    <div class="label-header">
                                        <label class="label">{{ $t('create-tags.users') }}</label>
                                    </div>

                                    <div class="input-box bg-white">
                                        <div class="icon-left"><icon-people /></div>
                                        <!-- <div class="icon-right" v-if="selectUsersId != ''" @click.stop="selectUsersId = ''"><icon-plus class="icon-clear"/></div> -->
                                        <multiselect 
                                            :preserve-search="true"
                                            v-model="usersSelected"
                                            class="select-tags-tbf hide-tags"
                                            :options="optionsUsers" 
                                            :multiple="true" 
                                            group-values="groupItems"
                                            group-label="groupName" 
                                            :group-select="true"
                                            :close-on-select="false"
                                            :placeholder="$t('create-tags.search-users')" 
                                            track-by="id" 
                                            label="first_name"
                                            :custom-label="customLabel"
                                            :selectLabel="$t('create-tags.select')"
                                            :selectGroupLabel="$t('create-tags.select-all')"
                                            :selectedLabel="$t('create-tags.selected')"
                                            :deselectLabel="$t('create-tags.deselect')"
                                            :deselectGroupLabel="$t('create-tags.deselect-all')"
                                            @input="selectUserId">
											<template slot="placeholder" slot-scope="props">
												<span class="text">
													{{ $t('create-tags.search-users') }}
												</span>
											</template>
											<template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('create-tags.selected-users') }}</span></template>
											<template slot="singleLabel" slot-scope="props">
												<div class="option__image">
													<span>
														{{ props.option.first_name + ' ' + props.option.last_name }}
													</span>
												</div>
											</template>
											<template slot="option" slot-scope="props">
												<div class="option__image">
													<img :src="props.option.avatar" v-if="props.option.avatar">
													<img v-else src="/build/images/profile-default.svg">
													<span>
														{{ props.option.first_name + ' ' + props.option.last_name }}
													</span>
												</div>
											</template>
											<template slot="noResult">{{ $t('create-tags.no-results') }}</template>
										</multiselect>
                                    </div>
                                    <div class="selectedValues" v-if="usersSelected.length > 0">
                                        <span v-for="usr, index in usersSelected" class="name">
                                            {{ usr.first_name + ' ' + usr.last_name }}
                                            <img src="/build/icons/settings-icon-delete-tag.svg" class="icon-remove-tag" @click="removeTaguser(usr, index)">
                                        </span>
                                        
                                    </div>
                                </div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
										<div class="loader"></div>
										<span class="text">{{$t('general.update')}}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'view'">
							<div class="header">
                                <div class="tag-color" v-bind:class="['color-' + colorName]">
                                    <span></span>
                                </div>
								<div class="name">{{ roleSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf blue only-icon" @click="editRole(roleSelected)">
										<div class="icon"><icon-edit /></div>
									</button>
									<button class="btn-tbf white only-icon" @click="resetToList('item')"><icon-close /></button>
								</div>
							</div>
							<div class="data-item">
								<div class="label">{{ $t('roles.name') }}</div>
								<div class="text">{{ roleSelected.name }}</div>

								<div class="label" v-if="roleSelected.users.length">{{ $t('roles.members') }}</div>
								<div class="text" v-if="roleSelected.users.length">
									<span class="user_name" v-for="(user, index) in optionsUsers[0].groupItems.filter(x => roleSelected.users.includes(x.id))">
										{{ user.first_name + ' ' + user.last_name }}<span v-if="index != Object.keys(optionsUsers[0].groupItems.filter(x => roleSelected.users.includes(x.id) )).length - 1">, </span> 
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-settings-page v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/EditSettings'
	import IconRole from '../../Icons/Role'
	import IconTrash from '../../Icons/Trash'
    import IconTags from "../../Icons/Tags"
    import IconPeople from "../../Icons/People"
    import IconCircleCheck from "../../Icons/CircleCheck"
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'

	import { required } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconRole,
            IconTags,
            IconPeople,
            IconCircleCheck,
			LoaderSettingsPage
		},
		data() {
			return {
				loaded: false,
				viewCrud: 'create',
				roles: [],
				roleSelected: '',
				name: '',
				description: '',
				error_message: '',
                selectedColor: '',
				colors: {},
                optionsUsers: [
					{
						groupName: {
							first_name: this.$t('create-tags.select-all'),
							last_name: ''
						},
						groupItems: []
					}
				],
				usersSelected: [],
				selectUsersId: [],
                // usersIds: [],
                colorName: '',
                errorsBe: {}
			}
		},
		async mounted(){
			await this.getRoles()
            await this.getColors()
            await this.getUsers()

			setTimeout(() => {
				var title = this.$t('settings-navbar.roles');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshRolesIndex', () => {
				this.viewCrud = 'create'
				this.getRoles()
                this.getColors()
                this.getUsers()
			})
		},
		validations: {
            selectedColor: {required},
			name: {required}
		},
		methods: {
            async getRoles(){
				await axios.get('/' + this.$auth.user().master_instance_id + '/tags')
				.then(({data}) => {
					this.roles = data.data
				})
				.catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
                .finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
            async getColors(){
				await axios.get('/colors').then(({data}) => {
					this.colors = data.data
				})
			},
			async getUsers(){
				await axios.get(`/instances/${this.$auth.user().instance_id}/filter`, { params: { users: true } })
				.then(({data}) => {
					this.optionsUsers[0].groupItems = data.data.users
					
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
        					this.$refs.first_input.focus()
						}, 0)
					}, 300)
				});
			},
			selectColor(colorId){
				this.selectedColor = colorId
			},
            customLabel({ first_name, last_name }){
				return `${first_name} ${last_name}`
			},
            selectUserId(value, id){
				this.selectUsersId = []
				value.map(el => {
					this.selectUsersId.push(el.id)
				})
			},
			removeTaguser(usr, index){
				this.usersSelected.splice(index,1);
				this.selectUsersId.splice(this.selectUsersId.findIndex(el => el == usr.id), 1)
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = ''

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
						name: this.name.charAt(0).toUpperCase() + this.name.slice(1),
						color_id: this.selectedColor,
					    users: this.selectUsersId
					}

					if(type == 'create'){
						this.createRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/tags/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						this.getRoles()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.roleSelected = data.data;
                            this.colorName = this.colors.filter(el => el.id == data.data.color_id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			updateRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/tags/${this.roleSelected.id}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getRoles()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.update')
							this.roleSelected = this.roles.find(el => el.id == this.roleSelected.id)
                            this.colorName = this.colors.filter(el => el.id == data.data.color_id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			editRole(role){
				this.scrollTop()
				this.viewCrud = 'edit'
                this.selectedColor = role.color.id
                this.usersSelected = role.users.length ? this.optionsUsers[0].groupItems.filter(el => role.users.includes(el.id)) : []
				
				this.roleSelected = role
				this.name = role.name
			},
			resetToList(from){
				this.viewCrud = 'create'
				if(from == 'item') {
					this.roleSelected = ''
				}
				this.name = ''
				this.selectedColor = []
				this.usersSelected = []
			},
			viewItem(item){
				this.scrollTop()
				this.viewCrud = 'view'
				this.roleSelected = item
                this.usersSelected = item.users.length ? this.optionsUsers[0].groupItems.filter(el => item.users.includes(el.id)) : []
			},
			showModal(type, model = false, typeDelete = false, fromDelete = false){
				this.$root.$emit('open_modal_v2', type, model, typeDelete, fromDelete);
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			}
		}
	};
</script>

<style lang="scss">
	.color-error{
		display: none;
		&.has_error{
			display: block;
			position: absolute;
			color: red;
			font-size: 12px;
			left: 87px;
		}
	}
</style>